.load-card {
  cursor: pointer;
  height: 100%;
  transition: transform 0.2s;
}

.load-card:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
